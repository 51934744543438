import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import SEO from '../components/Seo'
import Layout from '../components/Layout'
import ContentContainer from '../components/ContentContainer'
import ProjectInfo from '../components/ProjectInfo'
import VimeoPlayer from '../components/VimeoPlayer'
import VideoShowcase from '../components/VideoShowcase'

const ArtworkContainer = styled.div`
  position: relative;
  width: 100%;
`

const TopVideoWrap = styled.div`
  width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`

const TainaCruz = ({ data }) => {
  const project = data.markdownRemark.frontmatter

  return (
    <Layout hideFooter>
      <SEO title={`${project.artist} | ${project.projectTitle}`} />
      <ContentContainer centered>
        <VideoShowcase style={{ minHeight: '100rvh' }}>
          <ArtworkContainer>
            <VimeoPlayer Src="https://player.vimeo.com/video/411790643?background=1&autopause=0" />
            <TopVideoWrap>
              <VimeoPlayer Src="https://player.vimeo.com/video/411793388?loop=true" />
            </TopVideoWrap>
          </ArtworkContainer>
        </VideoShowcase>
      </ContentContainer>
      <ProjectInfo
        projectTitle={project.projectTitle}
        projectDescription={project.projectDescription}
        artist={project.artist}
        artistHeadshot={project.artistHeadshot.childImageSharp.fluid}
        artistDescription={project.artistDescription}
        website={project.website}
        instagram={project.instagram}
        twitter={project.twitter}
        email={project.email}
      />
    </Layout>
  )
}

export default TainaCruz

export const TainaCruzQuery = graphql`
  query TainaCruzQuery {
    markdownRemark(fields: { slug: { eq: "/taina-cruz/" } }) {
      id
      frontmatter {
        artist
        artistHeadshot {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        artistDescription
        projectTitle
        projectDescription
        website
        instagram
        twitter
        email
      }
    }
  }
`
